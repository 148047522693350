.home {
  min-height: calc(100vh - 64px);
  display: flex;

  &__description {
    padding: 50px;
    max-width: 800px;
    margin: auto;
  }

  &__image {
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  i {
    font-size: 24px;
  }
}

@media only screen and (max-width: @screen-sm) {
  .home {
    height: 100%;

    &__description {
      padding: 20px;
    }
  }
}
