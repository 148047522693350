.card {
  &__medicalfiletype {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    cursor: unset;
    cursor: pointer;
    height: 100%;

    &__cover {
      height: 300px;
      max-height: 30vh;
      object-fit: cover;
    }

    &__action {
      font-size: 16;
      padding: 10px 0;

      span {
        max-width: 100%;
        color: @primary-color;
      }
    }
  }
}

.medicalfiles {
  &__createbutton {
    margin: auto;
    margin-top: 20px;
    display: block;
  }

  &__validatebutton {
    background-color: @layout-header-background;
    color: #ffffff;

    &:hover {
      background-color: #6b5f59;
      color: #ffffff;
      border-color: transparent;
    }
  }

  &__category {
    background: @primary-color;
    color: #ffffff !important;
    padding: 5px 10px;
  }
}

.healthform {
  &__descriptions {
    .ant-descriptions-item-label {
      width: 50%;
    }
  }
}
