@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './home.less';
@import './dashboard.less';
@import './medicalfiles.less';
@import './viewer.less';

@primary-color: #00008f;
@layout-header-background: #00008f;
@layout-sider-background: #ffffff;
@layout-trigger-background: #00008f;

.container {
  width: 100%;
  display: block;
  align-self: center;
  margin: auto;
  padding: 24px 48px;
}

.make-container(@minWidth, @breakpoint) {
  @media (min-width: @minWidth) {
    .container {
      max-width: @breakpoint;
    }
  }
}

.make-container(@screen-xs-min, @screen-xs);
.make-container(@screen-sm-min, @screen-sm);
.make-container(@screen-md-min, @screen-md);
.make-container(@screen-lg-min, @screen-lg);
.make-container(@screen-xl-min, @screen-xl);

// .ant-descriptions-row:not(:last-child) {
//   border-bottom: 1px solid @normal-color !important;
// }

.step {
  font-size: 16px;
  background-color: white;
  padding: 10px 15px;
  text-transform: uppercase;
  border: 2px solid @primary-color;
  border-top: none;
  border-bottom: none;
}

ol.list {
  &__numbered {
    list-style: none;
    list-style-type: none;
    counter-reset: my-counter;
    li {
      counter-increment: my-counter;

      &::before {
        content: counter(my-counter) '.)';
        font-weight: bold;
      }
    }
  }
}

.sider {
  &__container {
    height: calc(100vh - 112px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 0px;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: @primary-color;
    }
  }
}

.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  z-index: 99;
  li {
    color: #ffffff !important;
  }

  &__logo {
    display: block;
    max-height: 64px;
    padding: 8px;

    &__wrapper {
      height: auto;
      padding: 0 0 0 20px;
      border: 0;
    }
  }
}

.content {
  margin-top: 64px;
}

.ant-comment.right .ant-comment-inner {
  justify-content: flex-end;
}

.ant-comment.right .ant-comment-content {
  flex: initial;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: @primary-color;
}
